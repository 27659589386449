// src/components/Success.js

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import axios from 'axios';

const Success = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showFallback, setShowFallback] = useState(false); // State to control fallback link visibility
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const readingId = query.get('reading_id');

  useEffect(() => {
    if (!readingId) {
      console.error('No reading ID found in URL.');
      setErrorMessage('No se encontró el ID de lectura en la URL.');
      return;
    }

    let pollingInterval = null; // To store the polling interval ID
    let fallbackTimer = null;   // To store the fallback timer ID

    console.log('Connecting to Socket.IO...');
    const socket = io(process.env.REACT_APP_SOCKET_IO_URL, {
      path: '/socket.io',
      transports: ['websocket', 'polling'],
    });

    socket.on('connect', () => {
      console.log('Connected to Socket.IO');
      socket.emit('join', { reading_id: readingId });
    });

    socket.on('reading_ready', (data) => {
      console.log('Received reading_ready event:', data);
      if (String(data.reading_id) === readingId) {
        console.log('Reading is ready! Navigating to Result.');
        navigate(`/result/${readingId}`);
      }
    });

    socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
      setErrorMessage('Hubo un problema de conexión. Verificando manualmente...');
      
      // Start polling as a fallback
      pollingInterval = setInterval(async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get_tarot_reading/${readingId}`);
          if (response.status === 200 && response.data.tarot_reading) {
            console.log('Reading is ready via polling. Navigating to Result.');
            clearInterval(pollingInterval);
            clearTimeout(fallbackTimer); // Clear the fallback timer if reading is ready
            navigate(`/result/${readingId}`);
          }
        } catch (pollError) {
          console.error('Polling error:', pollError);
          // Optionally, you can update the error message or retry logic here
        }
      }, 5000); // Poll every 5 seconds
    });

    // Set a 15-second timer to show the fallback link
    fallbackTimer = setTimeout(() => {
      console.log('Fallback timer triggered. Showing manual link.');
      setShowFallback(true);
    }, 25000); // 25 seconds

    return () => {
      socket.disconnect();
      console.log('Disconnected from Socket.IO');
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
      if (fallbackTimer) {
        clearTimeout(fallbackTimer);
      }
    };
  }, [readingId, navigate]);

  if (errorMessage) {
    return (
      <div className="error-message">
        <p>{errorMessage}</p>
        <button onClick={() => navigate(`/result/${readingId}`)}>Ir a los Resultados</button>
      </div>
    );
  }

  return (
    <div className="loading-container">
      <div className="loading-animation">
        <div className="icon">🔮</div>
        <div className="loading-text">
          <h2>Generando tu lectura...</h2>
          <p>Tu lectura se está generando. Por favor, espera unos momentos.</p>
          <p>Te avisaremos por correo cuando esté lista.</p>
          <p><em>También revisa tu bandeja de Spam.</em></p>
        </div>
      </div>

      {/* Fallback Link Displayed After 15 Seconds */}
      {showFallback && (
        <div className="fallback-link-container">
          <p>Si tu tarot no abre, visita este enlace:</p>
          <button onClick={() => navigate(`/result/${readingId}`)} className="fallback-link-button">
            Ver tu tarot
          </button>
        </div>
      )}

    </div>
  );
};

export default Success;
